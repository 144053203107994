import React from 'react'

import styled from '@emotion/styled'

export function renderSuggestion(suggestion = {}) {
  suggestion = formatSuggestion(suggestion)

  return (
    <Suggestion id={suggestion.label}>
      <SuggestionIcon src={suggestion.icon} />
      <SuggestionText>
        <SuggestionTitle>{suggestion.title}</SuggestionTitle>
        <SuggestionBody>{suggestion.body}</SuggestionBody>
      </SuggestionText>
    </Suggestion>
  )
}

export function formatSuggestion(suggestion) {
  if (!suggestion) return

  suggestion.title = suggestion.business ? suggestion.title : suggestion.street || suggestion.zip || suggestion.city

  suggestion.body = (
    suggestion.street
      ? [suggestion.business ? suggestion.street : null, suggestion.city, suggestion.state]
      : [suggestion.zip ? suggestion.city : null, suggestion.state]
  )
    .filter(Boolean)
    .join(', ')

  // for recent address' we save a custom 'recent.svg' icon. Let's make sure we respect it.
  if (!suggestion.icon) {
    suggestion.icon = suggestion.business
      ? '/static/location/office.svg'
      : suggestion.street
      ? '/static/location/pin.svg'
      : '/static/location/global-pin.svg'
  }

  return suggestion
}

export function getSuggestionValue(suggestion) {
  if (!suggestion.description) {
    return suggestion.title
  }

  // We want to remove 'United States' from the end of a suggestions description
  return suggestion.description.split(', ').slice(0, -1).join(', ')
}

const Suggestion = styled.div`
  padding: 0.4rem 0.8rem;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (min-width: 375px) and (max-width: 767px) {
    padding: 0.667rem;
  }
`

const SuggestionText = styled.div`
  width: 100%;
  overflow: hidden;
  text-align: left;
  position: relative;
  display: flex;
  white-space: nowrap;
  flex-shrink: 0;

  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 375px) and (max-width: 767px) {
    font-size: 1.33rem;
    padding-left: 0.1667rem;
  }
`

const SuggestionTitle = styled.div`
  font-size: 1.4rem;
  color: rgb(38, 50, 56);
  display: flex;
  flex-shrink: 0;

  @media (min-width: 768px) {
    margin-bottom: 0.4rem;
  }

  @media (max-width: 767px) {
    white-space: nowrap;
  }

  @media (min-width: 375px) and (max-width: 767px) {
    font-size: 1.167rem;
  }
`

const SuggestionBody = styled.div`
  font-size: 1.4rem;
  color: #7e8487;

  @media (max-width: 767px) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
  }

  @media (min-width: 375px) and (max-width: 767px) {
    font-size: 1.167rem;
  }
`

const SuggestionIcon = styled.img`
  width: 1.4rem;
  height: 2.1rem;
  margin-left: 0.4rem;
  margin-right: 2rem;
  display: flex;
  flex-shrink: 0;

  @media (min-width: 375px) and (max-width: 767px) {
    width: 1.667rem;
    margin-left: 0.333rem;
    margin-right: 0.667rem;
  }
`
